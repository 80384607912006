/* eslint-disable @typescript-eslint/no-explicit-any */
import { GradeInterface } from "@/ts/interfaces/Checkout/GradeInterface";
import { ClassesEnum } from "@ts/enums/ClassesEnum";
import { PageQueryParamsInterface } from "@ts/interfaces/Checkout/PageQueryParamsInterface";
import { PlanInterface } from "@ts/interfaces/Checkout/PlanInterface";

export const sortItems = <T extends Record<keyof T, unknown>>(
    data: T[],
    sorterKey: keyof T,
    childrenKeys: string[]
): T[] => {
    if (data.length <= 0) return [];
    data.sort(
        (item1, item2) =>
            (item1[sorterKey] as number) - (item2[sorterKey] as number)
    );
    // eslint-disable-next-line prefer-const
    for (let childKey of childrenKeys) {
        data.forEach((d: Record<string, unknown>) => {
            if (Object.prototype.hasOwnProperty.call(d, childKey)) {
                sortItems((d[childKey] ?? []) as T[], sorterKey, childrenKeys);
            }
        });
    }
    return data;
};

export const sortProducts = <T extends Partial<Record<keyof T, any>>>(
    data: T[],
    sorterKey: keyof T
): T[] => {
    if (data.length <= 0) return [];
    data.sort(
        (item1, item2) =>
            (item1[sorterKey] as number) - (item2[sorterKey] as number)
    );
    return data;
};

export const formatPrice = (price: number, currency?: string) =>
    `$${price.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    })} ${currency ?? "AUD"}`;

export const sortByProperty = <T>(array: T[], property: keyof T) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return array
        .slice()
        .sort((a, b) => (a[property] as any) - (b[property] as any));
};

/**
 * Retrieves and returns the final segment of a specified path string.
 * @param {string} path - The path string from which the last segment is extracted.
 * @returns {string} - The last segment found within the provided URL path.
 * eg: http://portal.local/parents/dashboard returns /dashboard
 */
export const getLastPathSegment = (path: string): string => {
    const segments = path?.split("/");
    return segments[segments.length - 1];
};

/**
 * Checks whether the provided URL corresponds to the current page.
 * @param {string} url - The URL for the page.
 * @returns {boolean} - True if it is the current URL; false otherwise.
 */
export const isActiveUrl = (url: string): boolean => {
    const desiredPath = getLastPathSegment(url);
    const currentPath = window.location.pathname;

    return currentPath?.startsWith(`/parents/${desiredPath}`);
};

export const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,10}$/;

export const MAX_NAME_LENGTH = 16;

export const truncateName = (
    name: string,
    maxLen: number = MAX_NAME_LENGTH
) => {
    return name?.length > maxLen ? `${name.slice(0, maxLen)}...` : name;
};

export const removeNullProperties = (
    obj: PageQueryParamsInterface
): { [key: string]: string } =>
    Object.fromEntries(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        Object.entries(obj).filter(([_, value]) => value !== null)
    );

export const getTrackingId = (
    classes: ClassesEnum[],
    action: string | number
) => {
    return `${classes.join(".")}.${action}`;
};

export const nameRegex = /^[a-zA-Z .'-]+$/g;

export const getPlansForTargetGrade = (
    plans: PlanInterface[],
    targetGrade: string
) => {
    const targetGradePlans = plans.filter(
        p => p.targetGradeName === targetGrade
    );

    if (targetGradePlans?.length > 0) {
        return targetGradePlans;
    }
    return plans;
};

/**
 * Intercom modal is used in few places and it might use in other places in future too.
 * @param closeFunc
 */
export const closeModalOpenIntercom = (closeFunc: () => void): void => {
    window?.Intercom("show");
    closeFunc();
};

export const formatSubjects = (
    subjects: Array<{ type: string; id: number; name: string }>
) => {
    return subjects?.map(subject => ({
        ...subject,
        value: subject.id,
        label: subject.name
    }));
};

export const returnGradeShortName = (
    grade: string | undefined,
    useAbbreviation: boolean = false
) => {
    switch (grade) {
        case "G11":
        case "Grade 11: University":
        case "Grade 11: Non-assessed":
            return useAbbreviation ? "G11" : "Grade 11";
        case "G12":
        case "Grade 12: University":
        case "Grade 12: Non-assessed":
            return useAbbreviation ? "G12" : "Grade 12";
        default:
            return grade;
    }
};

export const generateHash = (str: string, seed = 0) => {
    let h1 = 0xdeadbeef ^ seed,
        h2 = 0x41c6ce57 ^ seed;
    for (let i = 0, ch; i < str.length; i++) {
        ch = str.charCodeAt(i);
        h1 = Math.imul(h1 ^ ch, 2654435761);
        h2 = Math.imul(h2 ^ ch, 1597334677);
    }
    h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507);
    h1 ^= Math.imul(h2 ^ (h2 >>> 13), 3266489909);
    h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507);
    h2 ^= Math.imul(h1 ^ (h1 >>> 13), 3266489909);
    return (4294967296 * (2097151 & h2) + (h1 >>> 0)).toString();
};

export const addressRegex = /^[a-zA-Z0-9\s#,/.-]+$/;
export const postalCodeRegex = /^[a-zA-Z0-9]+$/;
export const phoneRegex = /^\d+$/;
export const suburbOrCityRegex = /^[a-zA-Z\s]+$/;

/**
 * Returns a list of available subjects based on the selected grade.
 * Using in the AgeGroupContainer and MembershipContainer to skip grade curriculum page if there're no subjects.
 */
export const getSubjectsByGrade = (
    subjectsByGrade: GradeInterface[],
    checkoutGrade: number
) => {
    const grades = subjectsByGrade?.filter(
        grade => grade.grade === checkoutGrade
    );

    const subjects = grades?.length !== 0 ? grades[0].subjects : [];
    return subjects;
};

export const convertDays = (days: number): string => {
    if (days >= 30) {
        const months = Math.floor(days / 30);
        const remainingDays = days % 30;
        let result = `${months} month${months > 1 ? "s" : ""}`;
        if (remainingDays > 0) {
            result += `, ${convertDays(remainingDays)}`;
        }
        return result;
    } else if (days >= 7) {
        const weeks = Math.floor(days / 7);
        const remainingDays = days % 7;
        let result = `${weeks} week${weeks > 1 ? "s" : ""}`;
        if (remainingDays > 0) {
            result += `, ${convertDays(remainingDays)}`;
        }
        return result;
    }
    return `${days} day${days > 1 ? "s" : ""}`;
};
